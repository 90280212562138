<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active"
  >
    <!--begin:: Add customer-->
    <div class="row gutter-b">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Form Customer</h6>
          </template>
          <div class="row">
            <div class="col-md-6">
              <div
                class="mb-4"
                v-for="(field, fieldIndex) in customerField"
                :key="fieldIndex"
              >
                <h6 class="mb-1">
                  {{ field.fieldName }}
                  <span class="text-danger" v-if="field.required">*</span>
                </h6>
                <b-form-select
                  v-model="customer[field.value]"
                  :options="optionCustomer"
                  value-field="id"
                  text-field="name"
                  :ref="field.value"
                  v-if="field.type === 'parent'"
                >
                  <option value="">Pilih Parent</option>
                </b-form-select>
                <b-form-input
                  v-model="customer[field.value]"
                  :placeholder="field.placeholder"
                  v-bind="field.bind"
                  :type="field.type"
                  :ref="field.value"
                  v-else
                ></b-form-input>
                <div class="text-danger" v-if="$v.customer[field.value].$error">
                  Tolong isi field
                  <span style="text-transform: lowercase">{{
                    field.fieldName
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <h6 class="mb-1">
                  Alamat Lengkap <span class="text-danger">*</span>
                </h6>
                <div>
                  <b-form-textarea
                    id="textarea-rows"
                    v-model="customer.address"
                    placeholder="Masukan alamat lengkap"
                    rows="8"
                    style="height: 116px"
                    ref="address"
                  ></b-form-textarea>
                </div>
                <div class="text-danger" v-if="$v.customer.address.$error">
                  Tolong isi field address
                </div>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">Provinsi</h6>
                <b-form-select
                  v-model="provinceSelected"
                  :options="optionProvince"
                  value-field="id"
                  text-field="name"
                >
                  <option :value="null">Pilih Provinsi</option>
                </b-form-select>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">Kabupaten/Kota</h6>
                <b-form-select
                  v-model="regencySelected"
                  :options="optionRegency"
                  value-field="id"
                  text-field="name"
                >
                  <option :value="null">Pilih Kabupaten</option>
                </b-form-select>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">Kecamatan</h6>
                <b-form-select
                  v-model="districtSelected"
                  :options="optionDistric"
                  value-field="id"
                  text-field="name"
                >
                  <option :value="null">Pilih Kecamatan</option>
                </b-form-select>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">Kelurahan</h6>
                <b-form-select
                  v-model="customer.village_id"
                  :options="optionVillage"
                  value-field="id"
                  text-field="name"
                >
                  <option :value="null">Pilih Kelurahan</option>
                </b-form-select>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <!-- <div class="row gutter-b">
      <div class="col-md-4">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Services</h6>
          </template>
          <b-form-checkbox
            v-for="(service, optionServiceIndex) in optionServices"
            :key="optionServiceIndex"
            :value="service.value"
            v-model="selectedServices"
          >
            {{ service.text }}
          </b-form-checkbox>
        </b-card>
      </div>
      <div class="col-md-8">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Kontak PIC</h6>
          </template>
          <div
            class="row"
            v-for="(list, picIndex) in listKontakPIC"
            :key="picIndex"
          >
            <div class="col-md-5">
              <div class="mb-4">
                <h6 class="mb-1">Nama PIC</h6>
                <b-form-input
                  v-model="list.name"
                  placeholder="Masukan nama pic"
                ></b-form-input>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <h6 class="mb-1">No Handphone</h6>
                <b-form-input
                  v-model="list.phone"
                  placeholder="Masukan no handphone"
                ></b-form-input>
              </div>
            </div>
            <div class="col-md-3">
              <div class="row justify-content-center">
                <div class="col" v-if="listKontakPIC.length === picIndex + 1">
                  <b-button
                    variant="primary"
                    class="w-100"
                    style="margin-top: 21px"
                    @click="handleAddPic()"
                    >Add</b-button
                  >
                </div>
                <div class="col" v-if="listKontakPIC.length > 1">
                  <b-button
                    variant="danger"
                    class="w-100"
                    style="margin-top: 21px"
                    @click="handleDeletePic(picIndex)"
                    >Delete</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div> -->
    <div class="row gutter-b">
      <div class="col-12">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Jadwal & Shift</h6>
            <span class="text-danger"
              >*Kosongkan Shift maka sistem tidak akan menyimpan data Jadwal &
              Shift pada saat Submit.</span
            >
          </template>
          <div class="row" v-for="(list, index) in listShift" :key="index">
            <div class="col-md-3">
              <div class="mb-4">
                <h6 class="mb-1">Shift</h6>
                <b-form-input
                  v-model="list.name"
                  placeholder="Masukan nama"
                  type="text"
                ></b-form-input>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <h6 class="mb-1">Jam Mulai Shift</h6>
                <b-form-input
                  v-model="list.start"
                  placeholder="Masukan start"
                  type="time"
                ></b-form-input>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <h6 class="mb-1">Jam Selesai Shift</h6>
                <b-form-input
                  v-model="list.end"
                  placeholder="Masukan end"
                  type="time"
                ></b-form-input>
              </div>
            </div>
            <div class="col-md-3">
              <div class="row">
                <div class="col-6" v-if="listShift.length === index + 1">
                  <b-button
                    variant="primary"
                    class="w-100"
                    style="margin-top: 21px"
                    @click="handleAddShift()"
                    >Add</b-button
                  >
                </div>
                <div class="col-6" v-if="conditionShiftDelete(list)">
                  <b-button
                    variant="danger"
                    class="w-100"
                    style="margin-top: 21px"
                    @click="handleDeleteShift(index)"
                    >Delete</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Sector</h6>
          </template>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-4">
                <h6 class="mb-1">Floor</h6>
                <b-form-input
                  v-model="sector.floor"
                  placeholder="Masukan lantai"
                  type="number"
                ></b-form-input>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <h6 class="mb-1">Sector</h6>
                <b-form-input
                  v-model="sector.sector"
                  placeholder="Masukan sector"
                  type="number"
                ></b-form-input>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div class="row gutter-b">
      <div class="col-12">
        <b-card header-tag="header" footer-tag="footer" class="gutter-b">
          <template v-slot:header>
            <h6 class="mb-0">Radius Absen</h6>
          </template>
          <div class="row m-0">
            <div class="col-md-6 p-0">
              <GmapMap
                :center="center"
                :zoom="zoomMap"
                map-type-id="terrain"
                style="width: 96%; height: 312px"
                @click="centerChanged"
              >
                <GmapCircle
                  v-for="(pin, indexCircle) in markers"
                  :key="indexCircle + 'a'"
                  :center="pin.position"
                  :radius="radiusMap"
                  :visible="false"
                  :options="{
                    fillColor: 'red',
                    fillOpacity: 0.2,
                    strokeColor: '#3151E2',
                    strokeWeight: 2,
                  }"
                ></GmapCircle>
                <GmapMarker
                  v-for="(m, indexMarker) in markers"
                  :key="indexMarker"
                  :position="m.position"
                  :clickable="true"
                  :draggable="true"
                  @dragend="updateCoordinates"
                  :options="{
                    url: 'require(@/public/media/custom/Vector.png)',
                  }"
                />
              </GmapMap>
            </div>
            <div class="col-md-6 p-0">
              <div class="mb-6">
                <h6 class="mb-1">Alamat</h6>
                <gmap-autocomplete
                  class="form-control"
                  :value="description"
                  @place_changed="setPlace"
                  :select-first-on-enter="true"
                  :options="{
                    componentRestrictions: { country: ['id'] },
                  }"
                >
                </gmap-autocomplete>
              </div>
              <div class="mb-6">
                <h6 class="mb-1">Radius</h6>
                <div class="row m-0">
                  <div class="col-md-10 p-0 align-self-center">
                    <vue-slider
                      v-model="radiusMap"
                      :min="100"
                      :max="1000"
                      :interval="50"
                    ></vue-slider>
                  </div>
                  <div class="col-md-2">
                    <input class="form-control" v-model="radiusMap" disabled />
                  </div>
                </div>
              </div>
              <div class="row mb-6">
                <div class="col-md-4">
                  <h6 class="mb-1">Pembatasan</h6>
                </div>
                <div class="col-md-4">
                  <b-form-checkbox
                    size="lg"
                    v-model="forbid_check_in_outside_threshold"
                    >Check in</b-form-checkbox
                  >
                </div>
                <div class="col-md-4">
                  <b-form-checkbox
                    size="lg"
                    v-model="forbid_check_out_outside_threshold"
                  >
                    Check out</b-form-checkbox
                  >
                </div>
              </div>
              <v-alert border="top" colored-border type="info" elevation="2">
                Jika anda melakukan pembatasan, karyawan yang absen diluar
                radius tidak akan bisa absen.
              </v-alert>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div class="row justify-content-center gutter-b">
      <div class="col-md-2">
        <b-button variant="primary" class="w-100" @click="buttonSubmit"
          >Submit</b-button
        >
      </div>
    </div>
    <!--end:: Add customer-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { gmapApi } from "vue2-google-maps";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "CustomerBranch",
  data() {
    return {
      // PROFILE Customer
      previewUrl: null,
      customer: {
        customer_id: "",
        name: "",
        address: "",
        province_id: null,
        district_id: null,
        phone: "",
        email: "",
        regency_id: null,
        village_id: null,
        // contract_number: "",
        // contract_start: "",
      },
      customerField: [
        {
          fieldName: "Parent",
          required: true,
          placeholder: "Pilih parent",
          value: "customer_id",
          type: "parent",
        },
        {
          fieldName: "Nama Client",
          required: true,
          placeholder: "Masukan nama client anda",
          value: "name",
          type: "text",
        },
        {
          fieldName: "Email",
          required: true,
          placeholder: "Masukan nama email anda",
          value: "email",
          type: "email",
        },
        {
          fieldName: "No Telpon",
          required: true,
          placeholder: "Masukan no telepon",
          value: "phone",
          type: "number",
        },
        // {
        //   fieldName: "Tanggal Berlaku Perjanjian",
        //   required: true,
        //   placeholder: "",
        //   value: "contract_start",
        //   type: "date",
        // },
        // {
        //   fieldName: "No Kontrak",
        //   required: true,
        //   placeholder: "Masukan no kontrak anda",
        //   value: "contract_number",
        //   type: "number",
        // },
      ],
      optionBloodType: [
        {
          value: "",
          text: "Pilih golongan darah",
        },
        {
          value: "A",
          text: "A",
        },
        {
          value: "AB",
          text: "AB",
        },
        {
          value: "B",
          text: "B",
        },
        {
          value: "O",
          text: "O",
        },
      ],
      optionCustomer: [],
      optionProvince: [],
      optionRegency: [],
      optionDistric: [],
      optionVillage: [],
      // SITE GROUP CUSTOMER
      selectedServices: [],
      optionServices: [
        { text: "Security", value: "security" },
        { text: "Driver", value: "driver" },
        { text: "Support", value: "support" },
        { text: "Cleaning Service", value: "cleaning service" },
      ],
      // Kontak PIC
      listKontakPIC: [
        {
          name: "",
          phone: "",
        },
      ],
      // Shift
      listShift: [
        {
          name: "",
          start: "00:00:00",
          end: "00:00:00",
        },
      ],
      // Sector
      sector: {
        floor: null,
        sector: null,
      },
      // MAPS
      // isRadiusCheckin: true,
      forbid_check_in_outside_threshold: false,
      forbid_check_out_outside_threshold: false,
      markers: [],
      center: {
        lat: -6.209044569159681,
        lng: 106.84463747406414,
      },
      location: {},
      place: null,
      description: "",
      addressMap: "",
      zoomMap: 10,
      radiusMap: 200,
    };
  },
  validations: {
    customer: {
      customer_id: {
        required,
      },
      name: {
        required,
      },
      address: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
        email,
      },
      // contract_start: {
      //   required,
      // },
      // contract_number: {
      //   required,
      // },
    },
  },
  components: {},
  computed: {
    google: gmapApi,
    provinceSelected: {
      get() {
        return this.customer.province_id;
      },
      set(value) {
        this.customer.province_id = value;
        this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/regencies/${value}`
          )
          .then(({ data }) => {
            this.optionRegency = data.list;
          });
      },
    },
    regencySelected: {
      get() {
        return this.customer.regency_id;
      },
      set(value) {
        this.customer.regency_id = value;
        this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/districts/${value}`
          )
          .then(({ data }) => {
            this.optionDistric = data.list;
          });
      },
    },
    districtSelected: {
      get() {
        return this.customer.district_id;
      },
      set(value) {
        this.customer.district_id = value;
        this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/villages/${value}`
          )
          .then(({ data }) => {
            this.optionVillage = data.list;
          });
      },
    },
  },
  mounted() {
    this.getData();
    let lastBreadcrumb = {};
    let branchTitle = [
      {
        title: "Customer",
        route: "/customer",
      },
      {
        title: "Customer Branch",
        route: `/customer/branch/list/${this.$route.params.customerId}`,
      },
    ];
    if (!this.$route.params.customerId)
      branchTitle = [
        {
          title: "Site",
          route: `/customer/branch/list`,
        },
      ];
    if (this.$route.name === "customer-branch-edit") {
      lastBreadcrumb = { title: "Edit Site" };
      this.getDataEdit();
    } else lastBreadcrumb = { title: "Add Customer" };
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      ...branchTitle,
      lastBreadcrumb,
    ]);
  },
  methods: {
    handelFoto(e) {
      const file = e.target.files[0];
      this.previewUrl = URL.createObjectURL(file);
    },
    handleDeletePic(index) {
      this.listKontakPIC.splice(index, 1);
    },
    handleAddPic() {
      this.listKontakPIC.push({
        name: "",
        phone: "",
      });
    },
    handleDeleteShift(index) {
      this.listShift.splice(index, 1);
    },
    handleAddShift() {
      this.listShift.push({
        name: "",
        start: "00:00:00",
        end: "00:00:00",
      });
    },
    // MAPS
    setDescription(description) {
      this.description = description;
    },
    setPlace(place) {
      if (!place) return;
      this.description = place.formatted_address;
      this.zoomMap = 15;
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      if (this.markers.length)
        this.markers.splice(0, 1, {
          position: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
        });
      else
        this.markers.push({
          position: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
        });
    },
    updateCoordinates(location) {
      // this.description = "(dragged on map)"
      this.markers.splice(0, 1, {
        position: {
          lat: location.latLng.lat(),
          lng: location.latLng.lng(),
        },
      });
      this.getAdrress(location.latLng.lat(), location.latLng.lng());
    },
    centerChanged(location) {
      // this.description = "(selected on map)"
      this.center = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      if (this.markers.length)
        this.markers.splice(0, 1, {
          position: {
            lat: location.latLng.lat(),
            lng: location.latLng.lng(),
          },
        });
      else
        this.markers.push({
          position: {
            lat: location.latLng.lat(),
            lng: location.latLng.lng(),
          },
        });
      this.getAdrress(location.latLng.lat(), location.latLng.lng());
    },
    async getAdrress(lat, lng) {
      const { VUE_APP_GMAPS_KEY } = process.env;
      this.description = "(" + lat + ", " + lng + ")";
      if (lat && lng) return;
      try {
        await this.$axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${VUE_APP_GMAPS_KEY}`
          )
          .then((response) => {
            this.description = response.results[0].formatted_address;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    async getData() {
      try {
        const request = [
          this.$axios.get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customers`,
            {
              params: {
                limit: 100000,
              },
            }
          ),
          this.$axios.get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/provinces`
          ),
        ];
        const [customer, province] = await Promise.all(request);
        this.optionCustomer = customer.data.list;
        this.optionProvince = province.data.list;
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message || "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    async buttonSubmit() {
      this.$v.customer.$touch();
      if (!this.$v.customer.$invalid) {
        const payload = {
          ...this.customer,
          sector: {
            floor: parseInt(this.sector.floor),
            sector: parseInt(this.sector.sector),
          },
          shifts: this.listShift,
          latitude: this.center.lat,
          longitude: this.center.lng,
          presence_distance_threshold: this.radiusMap,
          forbid_check_in_outside_threshold:
            this.forbid_check_in_outside_threshold,
          forbid_check_out_outside_threshold:
            this.forbid_check_out_outside_threshold,
          status: true,
        };
        try {
          let message = "Successfully add customer";
          if (this.$route.name === "customer-branch-edit") {
            delete payload.sector;
            message = "Successfully edit customer";
            await this.$axios.put(
              `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer-branch/${this.$route.params.id}`,
              payload
            );
          } else {
            await this.$axios.post(
              `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer-branch`,
              payload
            );
          }
          this.$bvToast.toast(message, {
            title: "Successs",
            variant: "success",
            solid: true,
          });
          this.$router.push({ name: "branch" });
        } catch ({ response }) {
          this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      } else {
        if (this.$v.customer.customer_id.$error)
          this.$refs["customer_id"][0].focus();
        else if (this.$v.customer.name.$error) this.$refs["name"][0].focus();
        else if (this.$v.customer.email.$error) this.$refs["email"][0].focus();
        else if (this.$v.customer.phone.$error) this.$refs["phone"][0].focus();
        // else if (this.$v.customer.contract_start.$error)
        //   this.$refs["contract_start"][0].focus();
        // else if (this.$v.customer.contract_number.$error)
        //   this.$refs["contract_number"][0].focus();
        else if (this.$v.customer.address.$error) this.$refs["address"].focus();
      }
    },
    async getDataEdit() {
      try {
        const {
          data: {
            customer_id,
            name,
            address,
            province: { id: province_id },
            regency: { id: regency_id },
            district: { id: district_id },
            village: { id: village_id },
            phone,
            email,
            shifts,
            latitude: lat,
            longitude: lng,
            forbid_check_in_outside_threshold,
            forbid_check_out_outside_threshold,
            presence_distance_threshold,
          },
        } = await this.$axios.get(
          `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer-branch/${this.$route.params.id}`
        );
        this.customer = {
          customer_id,
          name,
          address,
          province_id,
          regency_id,
          district_id,
          village_id,
          phone,
          email,
        };
        this.listShift = shifts;
        // MAP
        this.radiusMap = presence_distance_threshold;
        this.center = {
          lat,
          lng,
        };
        this.markers = [
          {
            position: {
              lat,
              lng,
            },
          },
        ];
        this.forbid_check_in_outside_threshold =
          forbid_check_in_outside_threshold;
        this.forbid_check_out_outside_threshold =
          forbid_check_out_outside_threshold;
        this.zoomMap = 15;
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    conditionShiftDelete(list) {
      if (this.$route.name == "customer-branch-edit") {
        return !list.id && this.listShift.length;
      }
      return this.listShift.length > 1;
    },
  },
};
</script>
